import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

class ApiSettings extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/settings'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  update(data: any) {
    return this.patch<any>('/update/operation', data);
  }

  getSettings(data: any) {
    return this.get<any>(`/get/operation?id=${data}`);
  }

}

export const apiSettings = new ApiSettings();
